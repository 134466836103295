var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex justify-end mr-2 my-2"},[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"success","loading":_vm.isSending},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("actions.sync")))])],1),_c('v-row',[_c('v-col',{staticClass:"col-6"},[_c('v-virtual-scroll',{attrs:{"items":_vm.lots,"height":"280","item-height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.lotsSelected),callback:function ($$v) {_vm.lotsSelected=$$v},expression:"lotsSelected"}},[_c('v-list-item',{key:item.lotNumberId,staticStyle:{"min-height":"20px"},attrs:{"value":item,"active-class":"deep-purple--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"dense":"","input-value":active,"color":"deep-purple accent-4"}})],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.lNumber)}})],1)]}}],null,true)}),_c('v-divider')],1)]}}])})],1),_c('v-col',{staticClass:"col-6"},[_c('v-virtual-scroll',{attrs:{"items":_vm.htcs,"height":"280","item-height":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.htcSelected),callback:function ($$v) {_vm.htcSelected=$$v},expression:"htcSelected"}},[_c('v-list-item',{key:item.id,attrs:{"value":item,"active-class":"deep-purple--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"deep-purple accent-4"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)]}}],null,true)})],1)]}}])})],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"col-12"},[_c('v-data-table',{key:this.$store.state.nmoKey,attrs:{"headers":_vm.lotDetailsHeader,"items":_vm.lotsSelected},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.productName)+" ")]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.expiryDate).format("YYYY-MM-DD"))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }