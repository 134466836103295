<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form ref="form" v-model="isValid">
      <v-card-text>
        <v-text-field
          class="pb-2"
          dense
          hide-details
          ref="nmoNameField"
          :label="getTranslation('nmo.creationForm.formFieldTitles.name', '*')"
          required
          v-model="nmo.name"
          outlined
          :disabled="!this.$store.getters.getIsAdmin"
          :rules="[
            () => !!nmo.name || $t('nmo.creationForm.errors.nameRequired'),
            () => !isNmoNameTaken || $t('nmo.creationForm.errors.nameTaken'),
          ]"
          @keydown="validateNmoNameUniqueness()"
        ></v-text-field>
        <v-select
          class="pb-2"
          dense
          hide-details
          :items="nmoTypes"
          :label="getTranslation('nmo.creationForm.formFieldTitles.type', '*')"
          required
          v-model="nmo.nmoTypeId"
          item-text="name"
          item-value="id"
          outlined
          :disabled="!this.$store.getters.getIsAdmin"
          :rules="[
            () => !!nmo.name || $t('nmo.creationForm.errors.typeRequired'),
          ]"
        ></v-select>
        <v-select
          class="pb-2"
          dense
          hide-details
          :items="countries"
          :label="
            getTranslation('nmo.creationForm.formFieldTitles.country', '*')
          "
          required
          v-model="nmo.countryId"
          item-text="name"
          item-value="id"
          outlined
          :disabled="!this.$store.getters.getIsAdmin"
          :rules="[
            () =>
              !!nmo.countryId || $t('nmo.creationForm.errors.countryRequired'),
          ]"
        ></v-select>
        <div class="d-flex">
          <v-checkbox
            class="pt-0 mt-1 pr-5"
            dense
            hide-details
            :label="$t('nmo.creationForm.formFieldTitles.share')"
            v-model="nmo.share"
            :disabled="!this.$store.getters.getIsAdmin"
          ></v-checkbox>
          <v-checkbox
            class="pt-0 mt-1"
            dense
            hide-details
            :label="$t('nmo.creationForm.formFieldTitles.isMultiHTC')"
            v-model="nmo.isMultiHTC"
            :disabled="!this.$store.getters.getIsAdmin"
          ></v-checkbox>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="success"
            @click="save()"
            :disabled="isSending || !isValid"
            >{{ $t("actions.save") }}</v-btn
          >
          <v-progress-circular
            v-if="isSending"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-card-text>
      <template v-if="isEdit">
        <v-tabs v-model="tab" height="30px" active-class="active-tab">
          <v-tab key="htc-key" class="tab">HTC</v-tab>
          <v-tab key="users-key" class="tab">{{
            $t("app.navBar.users")
          }}</v-tab>
          <!-- <v-tab key="lot-key" class="tab">{{
            $t("app.navBar.lotNumbers")
          }}</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab" style="border: 1px solid lightgray">
          <v-tab-item key="htc-key">
            <div class="d-flex py-1">
              <v-spacer></v-spacer>
              <v-btn text tile class="green--text" @click="openHTCDetail(-1)"
                ><v-icon>mdi-plus</v-icon>{{ $t("actions.add") }}</v-btn
              >
            </div>
            <v-card-text class="pt-0">
              <v-data-table
                dense
                :headers="headers"
                :key="this.$store.state.htcKey"
                :items="htc"
                :sort-by="['name']"
                :loading="isDataHTCLoading"
                :loading-text="$t('app.loading')"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
                :items-per-page="this.$store.state.itemsPerPage"
              >
                <template v-slot:item.action="{ item }">
                  <v-btn icon @click="openHTCDetail(item.id)">
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.countryCode="{ item }">
                  <span>{{ $t("country." + item.countryCode) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-tab-item>
          <v-tab-item key="users-key">
            <div class="d-flex py-1">
              <v-spacer></v-spacer>
              <v-btn text tile class="green--text" @click="openUserDetail(-1)"
                ><v-icon>mdi-plus</v-icon>{{ $t("actions.add") }}</v-btn
              >
            </div>
            <v-card-text class="pt-0">
              <v-data-table
                dense
                :headers="userHeaders"
                :key="this.$store.state.userKey"
                :items="users"
                :sort-by="['firstName']"
                :loading="isDataUserLoading"
                :loading-text="$t('app.loading')"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50, -1],
                }"
                :items-per-page="this.$store.state.itemsPerPage"
              >
                <template v-slot:item.action="{ item }">
                  <v-btn icon @click="openUserDetail(item.id)">
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.creationDate="{ item }">
                  <span v-if="item.creationDate != null">
                    {{ item.creationDate | moment("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                </template>
                <template v-slot:item.activationDate="{ item }">
                  <span v-if="item.activationDate != null">
                    {{ item.activationDate | moment("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-tab-item>
          <v-tab-item key="lot-key">
            <related-lot-htc :countryID="nmo.countryId" :nmoID="nmo.id" />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import RelatedLotHtc from "../../NMO/RelatedLotHtc";
export default {
  props: ["parameter"],
  components: {
    RelatedLotHtc,
  },
  data() {
    return {
      isValid: false,
      isSending: false,
      isShowContent: false,
      isDataHTCLoading: false,
      isDataUserLoading: false,
      isDataLoading: false,
      isNmoNameTaken: false,
      nmoNameTimeout: null,
      showSnackbar: false,
      snackBarMessage: "SAVED",
      showSnackbarColor: "success",
      nmo: {},
      nmoTypes: [],
      htc: [],
      users: [],
      countries: [],
      types: [],
      tab: null,
      headers: [
        {
          text: this.$t("htc.headerRow.action"),
          value: "action",
          class: "grey white--text",
        },
        {
          text: this.$t("htc.headerRow.nmoName"),
          value: "nmoName",
          class: "grey white--text",
        },
        {
          text: this.$t("htc.headerRow.country"),
          value: "countryCode",
          class: "grey white--text",
        },
        {
          text: this.$t("htc.headerRow.htcName"),
          value: "name",
          class: "grey white--text",
        },
        {
          text: this.$t("htc.headerRow.lastReportDate"),
          class: "grey white--text",
        },
      ],
      userHeaders: [
        {
          text: this.$t("htc.headerRow.action"),
          value: "action",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.firstName"),
          value: "firstName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.lastName"),
          value: "lastName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.email"),
          value: "email",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.phoneNumber"),
          value: "phoneNumber",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.type"),
          value: "userTypeName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.htcName"),
          value: "htcName",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.creationDate"),
          value: "creationDate",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.activationDate"),
          value: "activationDate",
          class: "grey white--text",
        },
        {
          text: this.$t("user.headerRow.notes"),
          value: "notes",
          class: "grey white--text",
        },
      ],
    };
  },
  computed: {
    isEdit() {
      return this.parameter.id > 0;
    },
    nmoName() {
      return this.nmo.name;
    },
  },
  watch: {},
  methods: {
    onSetTitle(title) {
      this.$emit("onSetTitle", title);
    },
    loadTypes() {
      this.isDataLoading = true;
      axios
        .get(this.$store.getters.getEnvironment + "nmotype/")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.nmoTypes.push({
              id: response.data[i].id,
              name: this.$t("nmo.type." + response.data[i].name),
            });
          }
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    loadCountries() {
      this.isDataLoading = true;
      axios
        .get(this.$store.getters.getEnvironment + "country/")
        .then((response) => {
          this.countries = response.data;
          for (let i = 0; i < this.countries.length; i++) {
            this.countries[i].name = this.$t(
              "country." + this.countries[i].countryCode
            );
          }

          // Sort countries by name
          this.countries.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // ignore case
            const nameB = b.name.toUpperCase(); // ignore case

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // names must be equal
          });

          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    loadHTC() {
      this.isDataHTCLoading = this.$store.state.loadingBarColor;
      axios
        .get(
          this.$store.getters.getEnvironment +
            "htc/getbynmoid/" +
            this.parameter.id
        )
        .then((response) => {
          this.htc = response.data;
          this.isDataHTCLoading = false;
        })
        .catch((e) => {});
    },
    loadUsers() {
      this.isDataUserLoading = this.$store.state.loadingBarColor;
      var xhr = axios.get(
        `${this.$store.getters.getEnvironment}user?nmoId=${this.parameter.id}`
      );

      return xhr
        .then((res) => {
          this.isDataUserLoading = false;
          this.users = res.data;
        })
        .catch((e) => {});
    },
    load() {
      this.isDataLoading = true;
      var xhr = axios.get(
        this.$store.getters.getEnvironment + "nmo/" + this.parameter.id
      );
      return xhr
        .then((response) => {
          this.nmo = response.data;
          this.isDataLoading = false;
          //   this.loadHTC();
        })
        .catch((e) => {});
    },
    save() {
      if (this.nmo.id == null) this.insert();
      else this.update();
    },
    insert() {
      this.isSending = true;
      axios
        .post(this.$store.getters.getEnvironment + "nmo/", this.nmo)
        .then((response) => {
          this.nmo.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setNMOKey");
          this.close();
        })
        .catch((e) => {});
    },
    update() {
      this.isSending = true;
      axios
        .put(
          this.$store.getters.getEnvironment + "nmo/" + this.nmo.id,
          this.nmo
        )
        .then((response) => {
          this.nmo.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setNMOKey");
          this.close();
        })
        .catch((e) => {});
    },
    openHTCDetail(value) {
      this.$store.commit("showModal", {
        componentName: "HTCDetail",
        parameter: { id: value, nmoId: this.parameter.id },
      });
    },
    openUserDetail(value) {
      this.$store.commit("showModal", {
        componentName: "UserDetail",
        parameter: { id: value, nmoId: this.parameter.id },
      });
    },
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
    validateNmoNameUniqueness() {
      if (this.nmoNameTimeout != null) {
        clearTimeout(this.nmoNameTimeout);
      }
      this.nmoNameTimeout = setTimeout(() => {
        var xhr = axios.get(
          `${this.$store.getters.getEnvironment}nmo/count?name=${this.nmoName}&exact=true`
        );
        xhr
          .then((res) => {
            this.isNmoNameTaken = res.data.nmoCount > 0;
            this.$refs["nmoNameField"].validate();
          })
          .catch((e) => {});
      }, 500);
    },
    close() {
      this.$store.commit("hideModal");
    },
  },
  async created() {
    if (this.isEdit) this.onSetTitle(this.$t("nmo.view.edit"));
    else this.onSetTitle(this.$t("nmo.view.create"));
    this.loadCountries();
    this.loadTypes();
    if (this.parameter.id != -1) {
      await this.load();
      this.loadHTC();
      this.loadUsers();
    }
  },
};
</script>
