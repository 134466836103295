<template>
  <v-container>
    <v-row class="d-flex justify-end mr-2 my-2">
      <v-btn
        class="mt-2"
        color="success"
        :loading="isSending"
        @click="save()"
        >{{ $t("actions.sync") }}</v-btn
      >
    </v-row>
    <v-row>
      <v-col class="col-6">
        <v-virtual-scroll :items="lots" height="280" item-height="25">
          <template v-slot:default="{ item }">
            <v-list-item-group v-model="lotsSelected" multiple>
              <v-list-item
                :key="item.lotNumberId"
                :value="item"
                active-class="deep-purple--text text--accent-4"
                style="min-height:20px"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action class="ma-0">
                    <v-checkbox
                      dense
                      :input-value="active"
                      color="deep-purple accent-4"
                      class="pa-0 ma-0"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content class="pa-0">
                    <v-list-item-title
                      v-text="item.lNumber"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>

              <v-divider></v-divider>
            </v-list-item-group>
          </template>
        </v-virtual-scroll>
      </v-col>
      <v-col class="col-6">
        <v-virtual-scroll :items="htcs" height="280" item-height="60">
          <template v-slot:default="{ item }">
            <v-list-item-group v-model="htcSelected" multiple>
              <v-list-item
                :key="item.id"
                :value="item"
                active-class="deep-purple--text text--accent-4"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="deep-purple accent-4"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </template>
        </v-virtual-scroll>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col class="col-12">
        <v-data-table
          :headers="lotDetailsHeader"
          :key="this.$store.state.nmoKey"
          :items="lotsSelected"
        >
          <template v-slot:item.name="{ item }">
            {{ item.product.productName }}
          </template>
          <template v-slot:item.expiryDate="{ item }">
            {{ $moment(item.expiryDate).format("YYYY-MM-DD") }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "NMO_HTC",
  props: {
    countryID: Number,
    nmoID: Number,
  },
  data() {
    return {
      lots: [],
      lotsSelected: [],
      htcs: [],
      htcSelected: [],
      lotDetailsHeader: [
        {
          text: this.$t("nmo.lot.columns.name"),
          value: "name",
          class: "grey white--text",
        },
        {
          text: this.$t("nmo.lot.columns.lotNumber"),
          value: "lNumber",
          class: "grey white--text",
        },
        {
          text: this.$t("nmo.lot.columns.expiryDate"),
          value: "expiryDate",
          class: "grey white--text",
        },
      ],
      isSending: false,
    };
  },
  methods: {
    async loadLots() {
      try {
        const response = await axios.get(
          this.$store.getters.getEnvironment +
            `lotnumber/country/${this.countryID}`
        );
        this.lots = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async loadHTCs() {
      try {
        const response = await axios.get(
          this.$store.getters.getEnvironment + `htc/getbynmoid/${this.nmoID}`
        );
        this.htcs = response.data;
      } catch (e) {
        console.log(e);
      }
    },
    async save() {
      try {
        this.isSending = true;
        const htcIds = this.htcSelected.map((item) => item.id);
        const lotNumberCountries = this.lotsSelected.map((item) => {
          return {
            lotNumberCountryId: item.lotDetails.lotNumberCountryId,
            isSelected: true,
          };
        });
        const payload = {
          htcIds: htcIds,
          lotNumberCountries: lotNumberCountries,
        };
        const response = await axios.put(
          this.$store.getters.getEnvironment + `LotNumber/htc/link-many`,
          payload
        );
        this.isSending = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {},
  watch: {
    countryID() {
      this.loadLots();
    },
  },
  created() {
    this.loadLots();
    this.loadHTCs();
  },
};
</script>
